
.contact {
    &__header {
        background: $primary;
        padding-top: 120px;
        padding-bottom: 80px;

        @include media-breakpoint-down(lg) {
            padding: 40px 0;
        }

        > div {
            h2 {
                max-width: 45%;
                color: $white;
                margin-bottom: 25px;

                @include font-size($font-large);

                font-weight: $font-weight--bold;

                @include media-breakpoint-down(lg) {
                    max-width: 100%;
                }
            }

            p {
                max-width: 45%;
                color: $white-lilac;
                margin-bottom: 0;

                @include font-size($font-medium);

                @include media-breakpoint-down(lg) {
                    max-width: 100%;
                }
            }
        }
    }

    &__content {
        padding-top: 60px;
        padding-bottom: 100px;
        background: $white-lilac;

        @include media-breakpoint-down(lg) {
            padding: 40px 0;
        }

        > div {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }
    }

    &__info {
        flex: 0 0 50%;

        @include media-breakpoint-down(lg) {
            flex: auto;
        }

        h3 {
            color: $black-stone;

            @include font-size($font-x-big);

            font-weight: $font-weight--bold;
        }

        p {
            color: $black-oak;
            margin-bottom: 35px;

            @include font-size($font-medium);

            @include media-breakpoint-down(lg) {
                margin-bottom: 0;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            @include media-breakpoint-down(lg) {
                margin: 30px 0;
            }

            li {
                color: $black-oak;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 15px;

                svg {
                    width: 25px;
                    height: 25px;
                    margin-right: 15px;
                    color: $black-oak;
                }
            }
        }
    }

    &__form {
        flex: 0 0 50%;
        background: $white;
        border-radius: 8px;
        padding: 40px 35px;
        box-shadow: 0 20px 30px rgba(25, 25, 46, 0.04);
        position: relative;
        margin-top: -260px;

        @include media-breakpoint-down(lg) {
            margin-top: 0;
            flex: auto;
            padding: 25px 15px;
        }

        form {
            > div {
                margin-bottom: 15px;

                label {
                    margin-bottom: 5px;

                    @include font-size($font-small);
                }

                input,
                textarea,
                select {
                    @include font-size($font-regular);
                }
            }

            button {
                margin-top: 15px;
                width: auto;
                padding: 10px 25px;
            }
        }
    }
}
