.explore-our-courses {
    padding: 50px 0;
    background: $white-lilac;

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 35px;

        > div {
            flex: 1;

            h2 {
                color: $black-stone;

                @include font-size($font-xx-big);

                font-weight: $font-weight--bold;
            }

            p {
                color: $black-oak;
                margin-bottom: 0;

                @include font-size($font-medium);
            }
        }

        a {
            width: auto;
            flex: 0 0 200px;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    &__item {
        height: 100%;
        background: $white;
        border-radius: 10px;
        border: 2px solid transparent;
        box-shadow: 0 20px 30px rgba(25, 25, 46, 0.04);
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }

        &:hover {
            //transform: scale(1.01);
            box-shadow: 0 6px 15px 0 rgba($primary, 0.06);
            border: 2px solid rgba($secondary, 0.4);
        }

        a {
            display: flex;
            flex-direction: column;
            height: 100%;

            img {
                border-radius: 8px 8px 0 0;
            }

            div {
                padding: 20px 15px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h3 {
                    color: $black-stone;
                    margin-bottom: 10px;

                    @include font-size($font-medium);

                    font-weight: $font-weight--semibold;
                }

                > p {
                    color: $black-oak;
                }

                aside {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    > p {
                        margin: 0;
                        color: rgba($black-oak, 0.8);

                        @include font-size($font-small);

                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 8px;

                            &.module-icon {
                                width: 20px;
                                height: 20px;
                                color: rgba($black-oak, 0.8);
                            }

                            &.workload-icon {
                                width: 20px;
                                height: 20px;
                                color: rgba($black-oak, 0.8);
                            }
                        }
                    }
                }
            }
        }
    }
}
