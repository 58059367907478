.new-journey {
    padding: 50px 0 20px;
    background: $white-lilac;
    position: relative;

    &--white {
        &::after {
            display: none;
        }

        .new-journey {
            &__header {
                h2 {
                    color: $black-stone;
                }

                p {
                    color: $black-oak;
                }
            }
        }
    }

    &::after {
        content: "";
        height: 70%;
        width: 100%;
        background: $primary;
        position: absolute;
        top: 0;
        z-index: 0;
    }

    &__header {
        margin-bottom: 35px;
        z-index: 1;

        h2 {
            color: $white;

            @include font-size($font-xx-big);

            font-weight: $font-weight--bold;
        }

        p {
            color: $white-lilac;
            margin-bottom: 0;

            @include font-size($font-medium);
        }
    }

    &__content {
        display: flex;
        gap: 25px;
        z-index: 1;

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            gap: 0;
            row-gap: 20px;
        }
    }

    &__item {
        width: 25%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;
        background: $white;
        box-shadow: 0 20px 30px rgba(25, 25, 46, 0.04);

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        svg {
            width: 60px;
            height: 60px;
            color: rgba($secondary, 0.6);
            margin-bottom: 15px;
        }

        h4 {
            color: $black-stone;
            margin-bottom: 15px;
            text-align: center;

            @include font-size($font-medium);

            font-weight: $font-weight--bold;
        }

        p {
            color: $black-oak;
            margin-bottom: 0;
            text-align: center;

            @include font-size($font-regular);
        }
    }
}
