.main-hero {
    border-radius: 0% 100% 28% 78% / 100% 0% 50% 0%;
    min-height: 770px;
    background: $blue-gem;
    position: relative;
    display: flex;
    align-items: center;
    background-image: url(../../images/bg.png);
    //background: linear-gradient(335deg, rgb(19, 4, 67) 0%, rgb(45, 35, 77) 35%, rgb(44, 59, 110) 100%);
    background-size: cover;
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
        border-radius: 0;
        min-height: auto;
    }

    .swiper {
        z-index: 0;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 100px;
        padding-bottom: 50px;

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
        }
    }

    &__content {
        flex: 0 0 45%;

        @include media-breakpoint-down(lg) {
            flex: auto;
            margin-top: 20px;
            order: 2;
        }

        h1 {
            color: $white;
            line-height: 1.1;
            margin-bottom: 20px;

            @include font-size($font-xxx-large);

            font-weight: $font-weight--semibold;
        }

        p {
            color: $white;
            margin-bottom: 25px;

            @include font-size($font-big);

            font-weight: $font-weight--regular;
        }

        > div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            a {
                width: auto;
                margin-right: 15px;
                margin-bottom: 15px;
                padding: 10px 25px;
            }
        }
    }

    &__image {
        flex: 0 0 45%;

        @include media-breakpoint-down(lg) {
            flex: auto;
            order: 1;
            margin-top: 50px;
        }

        img {
            width: 100%;
            border-radius: 10px;
        }
    }

    &__swiper-nav-next {
        width: 40px;
        height: 40px;
        padding: 15px;
        font-size: 12px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0 6px 15px 0 rgba($primary, 0.04);

        &::after {
            margin-left: 3px;
            color: $primary;
            font-size: 18px;
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__swiper-nav-prev {
        width: 40px;
        height: 40px;
        padding: 15px;
        font-size: 12px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0 6px 15px 0 rgba($primary, 0.04);

        &::after {
            margin-right: 3px;
            color: $primary;
            font-size: 18px;
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.icon-row {
    padding: 35px 0;

    @include media-breakpoint-down(lg) {
        padding-bottom: 15px;
    }

    &__item {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(lg) {
            padding: 20px;
            justify-content: center;
            border: 2px solid rgba($secondary, 0.3);
            margin-bottom: 20px;
            border-radius: 10px;
        }

        svg {
            width: 40px;
            height: 40px;
            margin-right: 20px;
            color: $primary;
        }

        > div {
            h4 {
                color: $primary;
                margin: 0;

                @include font-size($font-big);

                font-weight: $font-weight--regular;
            }

            p {
                color: $black-oak;
                margin: 0;

                @include font-size($font-regular);

                font-weight: $font-weight--light;
            }
        }
    }
}
